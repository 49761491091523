<template>
  <div
    class="page homepage"
    :newsletter-opened="newsletterOpened"
    :loaded="
      Boolean(settings) &&
      Boolean(posts) &&
      Boolean(home)
    "
  >
    <section class="top-section" v-if="home">
      <div class="wrapper">
        <div class="container" :style="{ backgroundImage: `url(${home.slides[0]})` }">
          <div class="content">
            <img class="logo" src="@/assets/images/wb_logo-white.svg" alt="WestBIC">
            <h1 v-html="home.headline.replace(/\n/g, '<br>')"></h1>
            <router-link class="btn white" to="/about-us">About us</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section text-section" v-if="home">
      <div class="wrapper">
        <div class="text-container">
          <div class="text-aside" v-html="home.subtitle.replace(/\n/g, '<br>')"></div>
          <div class="text-paragraph">
            <div class="text">
              <p v-html="home.description.replace(/\n/g, '<br>')"></p>
              <p><b>{{ home.cta_text }}</b></p>
            </div>
            <router-link class="btn medium" to="/contact">Contact us</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section metrics" v-if="home && home.metrics">
      <div class="wrapper">
        <div class="section-title centered" v-if="home.metricsText">
          <h3 v-html="home.metricsText.title.replace(/\n/g, '<br>')"></h3>
          <p>{{home.metricsText.description}}</p>
        </div>
        <div class="container">
          <div class="block" v-for="(metric, i) in home.metrics.filter((metric) => metric.value && metric.label)" :style="{ backgroundImage: `url(${metric.image})` }" :key="`metric-${i}`">
            <div class="block-content">
              <b>{{metric.value}}</b>
              {{metric.label}}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="top-section wwd" v-if="home">
      <div class="wrapper">
        <div class="container" :style="{ backgroundImage: `url(${home.wwd.image})` }">
          <div class="content">
            <h3 v-html="home.wwd.title.replace(/\n/g, '<br>')"></h3>
            <router-link class="btn medium white" to="/about-us">About us</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section text-section" v-if="home">
      <div class="wrapper">
        <div class="text-container">
          <div class="text-aside" v-html="home.wwd.subtitle.replace(/\n/g, '<br>')"></div>
          <div class="text-paragraph">
            <div class="text">
              <p v-html="home.wwd.description.replace(/\n/g, '<br>')"></p>
              <p><b>{{ home.wwd.cta_text }}</b></p>
            </div>
            <router-link class="btn medium" to="/contact">Contact us</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section news" v-if="posts && posts.length">
      <div class="wrapper">
        <div class="section-title centered">
          <h3>In The News</h3>
          <h5>All our latest blog articles</h5>
        </div>
        <swiper
          ref="slider"
          :options="{
            slidesPerView: 1.1,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            spaceBetween: 24,
            loop: false,
            grabCursor: true,
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            breakpoints: {
              1440: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 2.2,
              },
            },
          }"
        >
          <swiper-slide v-for="post in posts.slice(0, 5)" :key="post.id">
            <div class="post-container">
              <NewsItem :post="post" />
            </div>
          </swiper-slide>
        </swiper>
        <div class="section-footer">
          <p>WestBIC powers up a diverse crew of cutting-edge startups, fueling fresh ideas, job growth, and prosperity across our region with bold innovation at the core.</p>
          <router-link class="btn medium" to="/news">All our articles</router-link>
        </div>
      </div>
    </section>

    <section class="section image-text" v-if="home && home.network">
      <div class="wrapper">
        <div class="container">
          <img :src="home.network.image" alt="" class="image">
          <div class="content">
            <h3 v-html="home.network.title.replace(/\n/g, '<br>')"></h3>
            <h5 v-html="home.network.subtitle.replace(/\n/g, '<br>')"></h5>
            <p v-html="home.network.description.replace(/\n/g, '<br>')"></p>
            <router-link class="btn medium" to="/bic-network">BIC Network</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section podcast" v-if="podcast">
      <div class="wrapper">
        <div class="container">
          <div class="podcast-details">
            <h3>{{podcast.title}}</h3>
            <div class="podcast-sources">
              <a v-if="podcast.platforms.spotify" :href="podcast.platforms.spotify" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/images/spotify.svg" alt="Listen on Spotify">
              </a>
              <a v-if="podcast.platforms.itunes" :href="podcast.platforms.itunes" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/images/itunes.svg" alt="Listen on iTunes">
              </a>
              <a v-if="podcast.platforms.soundcloud" :href="podcast.platforms.soundcloud" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/images/soundcloud.svg" alt="Listen on Soundcloud">
              </a>
              <a v-if="podcast.platforms.youtube" :href="podcast.platforms.youtube" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/images/youtube.svg" alt="Listen on YouTube">
              </a>
            </div>
            <div class="podcast-description">
              <p v-html="podcast.description.replace(/\n/g, '<br>')"></p>
            </div>
            <div class="share" v-if="podcast.podcast">
              <span class="share-label">Share {{podcast.podcast.title}}</span>
              <div class="socials">
                <a :href="`mailto:?subject=${podcast.title}&body=${podcastLink}`" class="social" target="_blank">
                  <Icon icon="envelope" type="far" size="small" />
                </a>
                <a :href="`https://www.facebook.com/sharer/sharer.php?u=${podcastLink}`" class="social" target="_blank">
                  <Icon icon="facebook" size="small" />
                </a>
                <a :href="`https://twitter.com/home?status=${podcast.title} ${podcastLink}`" class="social" target="_blank">
                  <Icon icon="x-twitter" type="fa-brands" size="small" />
                </a>
                <a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${podcastLink}&title=${podcast.title}`" class="social" target="_blank">
                  <Icon icon="linkedin" size="small" />
                </a>
                <a ref="podcastLink" :active="copied" :data-clipboard-text="podcastLink" class="social">
                  <Icon icon="link" type="fas" size="small" />
                  <div class="valid">Copied!</div>
                </a>
              </div>
            </div>
            <router-link class="btn medium" to="/podcast">Listen to all episodes</router-link>
          </div>
          <img :src="podcast.image" :alt="podcast.title" class="podcast-cover">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewsItem from "./NewsItem.vue";
import DateFormat from "./DateFormat.vue";
import BpsImage from "./BpsImage.vue";
import Icon from "./Icon.vue";
import Clipboard from 'clipboard';

export default {
  name: "Home",
  components: {
    NewsItem,
    DateFormat,
    BpsImage,
    Icon,
  },
  data() {
    return {
      selectedIndex: 0,
      activeIndex: 0,
      activeTopIndex: 0,
      slides: null,
      podcast: null,
      currentTime: Date.now(),
      home: null,
      loading: false,
      newsletterEmail: "",
      newsletterOpened: false,
      newsletterLoading: false,
      newsletterSuccess: false,
      newsletterError: false,
      copied: false,
    };
  },
  computed: {
    ...mapGetters({
      posts: "getPosts",
      settings: "getSettings",
      isMobile: "getMobile",
      functions: "getFunctions",
      testMode: "getTest",
    }),
    podcastLink() {
      return `https://${window.location.host}/podcast`;
    },
    selectedPost() {
      return Array.isArray(this.posts) ? this.posts[this.selectedIndex] : null;
    },
    selectedImage() {
      if (!this.selectedPost) return null;
      return this.getImage(this.selectedPost["content:encoded"]);
    },
    screenWidth() {
      return window.innerWidth;
    },
    db() {
      return this.$store.state.firebase.firestore;
    },
    tweets() {
      return (
        (this.settings &&
          this.settings.latest_tweets &&
          this.settings.latest_tweets.tweets) ||
        []
      );
    },
  },
  watch: {
    testMode() {
      this.getItems();
    },
  },
  methods: {
    getDateFormat(date) {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    },
    subscribeNewsletter() {
      if (this.newsletterLoading) return false;
      this.newsletterError = this.newsletterSuccess = false;
      this.newsletterLoading = true;
      this.functions
        .httpsCallable("subscribeNewsletter")({
          email: this.newsletterEmail,
          test: this.testMode,
        })
        .then(() => {
          this.newsletterEmail = "";
          this.newsletterLoading = false;
          this.newsletterSuccess = true;
        })
        .catch(() => {
          this.newsletterLoading = false;
          this.newsletterError = true;
        });
    },
    getImage(content) {
      const div = document.createElement("div");
      div.innerHTML = content;
      const img = div.querySelector("img");
      if (img) return img.src;
      return null;
    },
    getItems() {
      if (this.homeRef) this.homeRef();
      if (this.podcastRef) this.podcastRef();
      clearInterval(this.topSliderInt);
      this.homeRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}settings/homepage`)
        .onSnapshot((settings) => {
          const isInit = !this.home;
          this.home = settings.data();
          if (isInit && this.home.slides) {
            this.$nextTick().then(() => {
              const swiper = this.$refs.slider.$el;
              swiper.addEventListener('mousewheel', function(e) {
                if (Math.abs(e.deltaX) > 0 || swiper.swiping) {
                  e.preventDefault();
                  clearTimeout(this.slideTO);
                  const translate = swiper.swiper.getTranslate() - e.deltaX;
                  swiper.swiper.translateTo(translate, 0);
                  this.slideTO = setTimeout(function() {
                    let finalTranslate = 0;
                    let slideIndex = 0;
                    for (let i = 0; i < swiper.swiper.slides.length; i++) {
                      const size = swiper.swiper.slides[i].swiperSlideSize;
                      const _translate = -swiper.swiper.getTranslate();
                      let updatedTranslate = finalTranslate + size;
                      if (updatedTranslate >= _translate) {
                        if (updatedTranslate - _translate < size * (e.deltaX < 0 ? 0.3 : 0.7)) {
                          finalTranslate = updatedTranslate;
                          slideIndex = i + 1;
                        }
                        break;
                      }
                      finalTranslate = updatedTranslate;
                      slideIndex = i + 1;
                    }
                    swiper.swiper.slideTo(slideIndex, 500);
                  }, 100);
                  return false;
                }
              });
            });
            this.topSliderInt = setInterval(() => {
              const index = this.activeTopIndex + 1;
              this.activeTopIndex = index < this.home.slides.length ? index : 0;
            }, 5000);
          }
        });
      this.podcastRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}pages/podcast`)
        .onSnapshot((settings) => {
          this.podcast = settings.data();
          if(this.podcast && this.podcast.podcast) {
            this.$nextTick().then(() => {
              const clip = new Clipboard(this.$refs.podcastLink);
              clip.on('success', () => {
                clearTimeout(this.copiedTO);
                this.copied = true;
                this.copiedTO = setTimeout(() => this.copied = false, 1500);
              })
            })
          }
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.homeRef) this.homeRef();
    if (this.podcastRef) this.podcastRef();
    clearInterval(this.topSliderInt);
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 64px;
  line-height: 1.166666;
  color: var(--Metallic-Seaweed);
}
.section-title h5 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 40px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.section-footer {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding: 0 96px;
}
.section-footer p::after {
  content: '';
  display: block;
  width: 587px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px auto 40px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  overflow: hidden;
  padding-top: 53.4%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 64px 96px;
  background: rgb(10,126,140);
  background: linear-gradient(36deg, rgba(10, 126, 140, 0.80) 13.82%, rgba(10, 126, 140, 0.50) 32.99%, rgba(10, 126, 140, 0.00) 64.59%);
}
.top-section.wwd .container .content {
  background: linear-gradient(0deg, rgba(14, 58, 90, 0.86) 0%, rgba(14, 58, 90, 0.70) 37.76%, rgba(14, 58, 90, 0.23) 100%);
}
.top-section .container .content .logo {
  display: block;
  width: 414px;
  max-width: 100%;
}
.top-section .container .content h1,
.top-section .container .content h3 {
  font-size: 64px;
  margin: 32px 0;
  line-height: 1.1625;
  max-width: 920px;
}
.top-section .container .content p {
  font-size: 18px;
}

.text-section .text-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
}
.text-section .text-container .text-aside {
  width: 40%;
  padding: 0 48px;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.16666666;
}
.text-section .text-container .text-paragraph {
  width: 60%;
  padding: 0 48px;
  font-size: 24px;
  font-weight: 500;
}
.text-section .text-container .text-paragraph p:not(:last-child) {
  margin-bottom: 32px;
}
.text-section .text-container .text-paragraph .text::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 40px 0;
}

.metrics .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
}
.metrics .block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: calc(25% - 24px);
  height: 600px;
  padding: 48px 24px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  border-radius: 8px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.metrics .block::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  background: linear-gradient(0deg, #0A7E8C 0%, rgba(10, 126, 140, 0.71) 37.86%, rgba(10, 126, 140, 0.00) 100.26%);
}
.metrics .block > * {
  position: relative;
}
.metrics .block b {
  display: block;
  font-weight: 900;
  font-size: 72px;
  margin-bottom: 8px;
}

.news {
  position: relative;
  overflow: hidden;
}
.news .post-container {
  height: 100%;
}
.news .swiper-slide {
  height: auto;
}
.news .swiper-container {
  overflow: initial;
}

.image-text .container {
  display: flex;
}
.image-text .image {
  width: 680px;
  object-fit: cover;
  border-radius: 12px;
}
.image-text .content {
  flex-grow: 1;
  padding: 80px 96px 80px 108px;
}
.image-text h3 {
  font-size: 64px;
  line-height: 1.166666;
  margin-bottom: 24px;
  color: var(--Metallic-Seaweed);
}
.image-text h5 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.166666;
  color: var(--Dark-Blue);
  margin-bottom: 32px;
}
.image-text p {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
}
.image-text p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px 0;
}

.podcast .container {
  display: flex;
}
.podcast .podcast-details {
  position: relative;
  padding: 64px 96px;
  color: var(--Dark-Blue);
}
.podcast .container .podcast-cover {
  width: 512px;
  object-fit: cover;
  border-radius: 16px;
}
.podcast .podcast-details h3 {
  font-size: 64px;
  margin-bottom: 16px;
  color: var(--Metallic-Seaweed);
}
.podcast .podcast-details h5 {
  font-size: 36px;
}
.podcast .share {
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 32px;
  margin-bottom: 40px;
}
.podcast .share::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 504px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
}
.podcast .share .share-label {
  font-size: 24px;
  font-weight: 600;
}
.podcast .podcast-description {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.166666;
  margin-bottom: 40px;
}
.podcast .podcast-sources {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
  margin: 40px 0;
}
.podcast .podcast-sources a {
  display: block;
}
.podcast .podcast-sources a img {
  height: 40px;
}
.podcast .socials {
  display: flex;
  gap: 8px;
}

@media screen and (max-width: 1280px) {
  .section-title {
    padding: 0 64px;
  }
  .section-title h3 {
    font-size: 48px;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content .logo {
    width: 300px;
  }
  .top-section .container .content h1 {
    font-size: 48px;
  }
  .text-section .text-container {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside {
    font-size: 28px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 21px;
  }
  .metrics .container {
    gap: 24px;
  }
  .metrics .block {
    width: calc(25% - 18px);
  }
  .image-text .image {
    width: 50%;
  }
  .image-text .content {
    padding: 64px;
  }
  .image-text h5 {
    font-size: 28px;
  }
  .image-text h5 >>> br {
    display: none;
  }
  .podcast .container .podcast-cover {
    width: 50%;
  }
  .podcast .container .podcast-details {
    padding: 48px;
  }
  .podcast .podcast-details h3 {
    font-size: 48px;
  }
  .podcast .share {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

@media screen and (max-width: 880px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 48px;
  }
  .section-title h3 {
    font-size: 40px;
  }
  .section-title h5 {
    font-size: 24px;
  }
  .section-footer {
    padding: 0;
  }
  .top-section {
    margin-bottom: 48px;
  }
  .top-section .container {
    min-height: 400px;
  }
  .top-section .container .content {
    padding: 48px;
  }
  .top-section .container .content .logo {
    width: 220px;
  }
  .top-section .container .content h1 {
    font-size: 32px;
  }
  .top-section .container .content h3 {
    font-size: 48px;
  }
  .text-section .text-container {
    display: block;
    padding: 0 24px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    width: auto;
  }
  .text-section .text-container .text-aside {
    margin-bottom: 24px;
  }
  .metrics .block {
    width: calc(50% - 12px);
    height: 400px;
    background-position: top center;
  }
  .image-text .container {
    display: block;
  }
  .image-text .image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
  .image-text .content {
    padding: 24px 48px 0;
  }
  .podcast .container {
    flex-direction: column-reverse;
  }
  .podcast .container .podcast-cover {
    width: 100%;
  }
  .podcast .container .podcast-details {
    padding: 48px 0;
  }
}

@media screen and (max-width: 580px) {
  .section-title {
    padding: 0;
  }
  .top-section .container .content {
    padding: 32px;
  }
  .top-section .container .content .logo {
    width: 200px;
  }
  .top-section .container .content h1 {
    font-size: 24px;
  }
  .top-section .container .content h3 {
    font-size: 32px;
  }
  .text-section .text-container {
    padding: 0;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0;
  }
  .text-section .text-container .text-aside {
    font-size: 21px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 18px;
  }
  .image-text h5 {
    font-size: 24px;
  }
  .image-text p {
    font-size: 21px;
  }
  .image-text .content {
    padding: 24px 0 0;
  }
  .podcast .podcast-details h3 {
    font-size: 40px;
  }
  .podcast .podcast-description {
    font-size: 21px;
  }
  .podcast .container .podcast-details {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .metrics .block {
    width: 100%;
  }
  .podcast .podcast-sources a img {
    height: 32px;
  }
}
</style>
